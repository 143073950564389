import "frontend-layout"
import "inputmask/dist/jquery.inputmask.min.js";
import "jquery-ui/ui/core.js";
import "jquery-ui/ui/widget.js";

import "./routes"

(function ($) {
    "use strict";

    window.$ = window.jQuery = $;

    $(function () {

        $(".x-card-mask").inputmask({
            'mask': "999999 9999 9999 9{4,5}",
            'removeMaskOnSubmit': true,
            'autoUnmask': true,
        });

        $(".x-card-pin").inputmask({
            'mask': "9999",
            'removeMaskOnSubmit': true,
            'autoUnmask': true,
        });

        $( ".accordion" ).accordion({
            collapsible: true,
            active: false,
            heightStyle: "content"
        });

      //begin of close .header__nav when click outside
      $(document).mouseup(function(e) {
          var $div = $(".header__nav,.header__toggle");
          if (!$div.is(e.target) && $div.has(e.target).length === 0 && (Foundation.MediaQuery.current == "small" || Foundation.MediaQuery.current == "medium")) {
              $('.header__nav').hide();
              $('#header-aside').addClass('show-for-large');
          }
      });
      //end of close .header__nav when click outside
      function sendFormCaptcha(el)
      {
        var $this = $(el);
        var $form = $('form[name=' + $this.data('form-name') + ']');
        $form.find('input[name=captcha_word]').val($this.parents('.x-captcha-form').find('input[name=captcha_word]').val());
        $form.find('[type=button]').prop('type', 'submit').trigger('click');
        $form.submit();
      }
    });
})(jQuery);

