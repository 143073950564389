;(function ($) {
  "use strict"
  $(function () {
    let $cardNotice = $(".x-cardNum-notice")

    window.personal = window.personal || {}
    window.personal.FUEL = window.personal.FUEL || {}
    window.personal.FUEL_INFO = window.personal.FUEL_INFO || {}
    window.personal.POINTS = window.personal.POINTS || {}
    window.personal.CODES = {}

    let downloadForm = $(".x-route-map-download")
    if (downloadForm.length) {
      downloadForm.find("a").click(function (e) {
        e.preventDefault()
        downloadForm.submit()
      })
    }

    $("#CARD_NUM").inputmask({
      mask: "999999 9999 9999 9{4,5}",
      autoUnmask: true,
      oncomplete: function () {
        $.ajax({
          method: "post",
          data: { ACTION: "filterCard", CARD_NUM: $("#CARD_NUM").val() },
          url: window.location.href,
          headers: { XHR: "XMLHttpRequest" },
          success: function (data) {
            data = JSON.parse(data)
            if (data.CODES) {
              $cardNotice.addClass("field__alert_ok").html("карта найдена")
              window.personal.CODES = data.CODES
            } else {
              $cardNotice.addClass("field__alert_wrong").html("карта не найдена")
              window.personal.CODES = false
            }
          },
        })
      },
      onincomplete: function () {
        $cardNotice.html("")
        window.personal.CODES = false
      },
    })

    if ($("#route-map").length > 0) {
      var map, currentRoute, objectManager;
      console.log('test');
      ymaps.ready(function () {
        map = new ymaps.Map("route-map", {
          center: [65.0, 95.0],
          zoom: 1,
          controls: ["fullscreenControl"],
        })
        window.YandexMaps = map;
        var zoomControl = new ymaps.control.ZoomControl({
          options: {
            size: "small",
            position: { right: 10, top: 200 },
          },
        })
        map.controls.add(zoomControl)
        map.controls.add("rulerControl", {
          scaleLine: false,
          position: { top: 10, right: 50, bottom: "auto", left: "auto" },
        })
        map.behaviors.disable("scrollZoom")

        objectManager = new ymaps.ObjectManager({ clusterize: true })
        objectManager.add(window.personal.POINTS)
        objectManager.clusters.options.set({ preset: "islands#redClusterIcons" })
        map.geoObjects.add(objectManager)

        map.setBounds(objectManager.getBounds(), { checkZoomRange: true })
      })

      var pointsStr = ""
      $.each(window.personal.POINTS.features, function (key, object) {
        pointsStr += object.id + ","
      })
      setDownloadLink(pointsStr, true)

      $(".x-map-search").on("click", function (e) {
        e.preventDefault()
        $("#route-map-filter-link").val("")
        var $this = $(this)
        var $form = $this.closest("form")
        var $pointA = $form.find('input[name="POINT_A"]')
        var $pointB = $form.find('input[name="POINT_B"]')
        var $pointsAdditional = $form.find('input[data-type="additionalPoint"]')
        var $trafficJams = $form.find('input[name="TRAFFIC_JAMS"]')
        var $mobilePoint = $form.find('input[name="MOBILE_POINT"]:checked')
        var $address = $form.find('input[name="ADDRESS"]')
        var $region = $form.find('select[name="REGION"]')
        var $cardType = $form.find('select[name="CARD_TYPE"]')
        var $brand = $form.find('select[name="BRAND"]')
        var $country = $form.find('select[name="COUNTRY"]')
        var $fuel = $form.find('input[name="FUEL[]"]:checked')
        var info = window.personal.FUEL_INFO
        var codes = window.personal.CODES

        var filter = function (object) {
          if ($mobilePoint.length && object.options.preset === "islands#redDotIcon") {
            return false
          }

          if (
            codes.length > 0 &&
            (typeof info[object.id] === "undefined" ||
              typeof info[object.id].KOD === "undefined" ||
              codes.indexOf(parseInt(info[object.id].KOD)) < 0)
          ) {
            return false
          }

          if (
            $address.length &&
            $address.val() !== "" &&
            object.id !== $address.val() &&
            (typeof info[object.id] === "undefined" ||
              typeof info[object.id].NAME === "undefined" ||
              info[object.id].NAME.toLowerCase().indexOf($address.val().toLowerCase()) < 0)
          ) {
            return false
          }

          if (
            $cardType.length &&
            $cardType.val() !== "" &&
            (typeof info[object.id] === "undefined" ||
              typeof info[object.id].CARD_TYPE === "undefined" ||
              info[object.id].CARD_TYPE.toLowerCase() !== $cardType.val().toLowerCase())
          ) {
            return false
          }

          if (
            $brand.length &&
            $brand.val() !== "" &&
            (typeof info[object.id] === "undefined" ||
              typeof info[object.id].BRAND === "undefined" ||
              info[object.id].BRAND.toLowerCase() !== $brand.val().toLowerCase())
          ) {
            return false
          }

          if (
            $country.length &&
            $country.val() !== "" &&
            (typeof info[object.id] === "undefined" ||
              typeof info[object.id].COUNTRY === "undefined" ||
              info[object.id].COUNTRY.toLowerCase() !== $country.val().toLowerCase())
          ) {
            return false
          }

          if (
            $region.length &&
            $region.val() !== "" &&
            (typeof info[object.id] === "undefined" ||
              typeof info[object.id].REGION === "undefined" ||
              parseInt(info[object.id].REGION) !== parseInt($region.val()))
          ) {
            return false
          }

          if ($fuel.length) {
            if (typeof info[object.id] === "undefined" || typeof info[object.id].FUEL_PRICE === "undefined") {
              return false
            }
            var ret = true
            $fuel.each(function () {
              if (typeof info[object.id].FUEL_PRICE[$(this).val()] !== "undefined") {
                ret = false
              }
            })
            if (ret) return false
          }
          return true
        }

        currentRoute && map.geoObjects.remove(currentRoute)
        if ($pointA.val().length > 0 && $pointB.val().length > 0) {
          $("#route-map-clock").removeClass("hide");
          var routeArray = [$pointA.val()];
          if ($pointsAdditional.length > 0) {
            $pointsAdditional.each(function () {
              if ($(this).val() !== "") routeArray.push($(this).val());
            });
          }
          routeArray.push($pointB.val());

          ymaps
            .route(routeArray, {
              multiRoute: false,
              avoidTrafficJams: $trafficJams.prop("checked"),
              mapStateAutoApply: true,
            })
            .done(
              function (route) {
                var pointsStr = "";
                currentRoute = route;
                var routeLength = Math.ceil(currentRoute.getLength() / 1000);
                $(".x-map-route-length-caption").html(
                  "Расстояние ~" +
                  routeLength +
                  " км.<br>При расходе 10 л на 100 км понадобится " +
                  routeLength / 10 +
                  " л топлива."
                );
                $(".x-map-route-length").removeClass("hide");
                map.geoObjects.add(route);
                var bounds = route.getPaths().getBounds();

                var startPoint = route.getWayPoints().get(0).geometry.getCoordinates();

                var pointsWithDistances = [];

                objectManager.setFilter(function (object) {
                  if (!filter(object)) return false;
                  if (typeof bounds === "undefined") {
                    pointsStr += object.id + ",";
                    return true;
                  }
                  var coordinates = object.geometry.coordinates;
                  if (
                    coordinates[0] > bounds[0][0] - 0.05 &&
                    coordinates[0] < bounds[1][0] + 0.05 &&
                    coordinates[1] > bounds[0][1] - 0.05 &&
                    coordinates[1] < bounds[1][1] + 0.05
                  ) {
                    var paths = route.getPaths().toArray() || {};
                    for (var i = 0; i < paths.length; i++) {
                      if (paths[i].geometry.getClosest(coordinates).distance < 5000) {
                        var distance = ymaps.coordSystem.geo.getDistance(startPoint, coordinates);
                        pointsWithDistances.push({
                          id: object.id,
                          coordinates: coordinates,
                          distance: distance,
                        });
                        return true;
                      }
                    }
                  }
                  return false;
                });

                pointsWithDistances.sort(function (a, b) {
                  return a.distance - b.distance;
                });
                pointsStr = pointsWithDistances.map(function (point) {
                  return point.id;
                }).join(",");

                if (pointsStr.length > 0) {
                  $("#route-map-filter-link").val(
                    "По этой ссылке вы можете загрузить список точек по маршруту: " +
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    window.location.pathname +
                    "?points=" +
                    btoa(pointsStr)
                  );
                  setDownloadLink(pointsStr);
                }
                $("#route-map-clock").addClass("hide");
              },
              function (err) {
                console.log(err);
                $(".x-map-route-length").addClass("hide");
                $("#route-map-clock").addClass("hide");
              },
              this
            );
        } else {
          var pointsStr = "";
          var result = null;
          objectManager.setFilter(function (object) {
            let result = filter(object);
            if (result) {
              pointsStr += object.id + ",";
            }
            return result;
          });
          if (pointsStr.length > 0) {
            setDownloadLink(pointsStr, true);
          }
        }
      })
      var addPointIterator = 0
      $(".x-field-input-add").on("click", function () {
        var point = "POINT_" + addPointIterator,
          pointLast = $("div[data-attr='POINT_B']"),
          pointInput = ""

        pointInput +=
          "<div class='field__input_additional' data-attr=" +
          point +
          ">" +
          "<input id=" +
          point +
          " type='text' name=" +
          point +
          " class='field__input x-kladr-address' data-type='additionalPoint' placeholder='Куда'>" +
          "<div class='field__input_delete'>X</div>" +
          "</div>"
        pointLast.before(pointInput)
        window.personal.addressSuggestions(pointLast.prev().children().first())
        addPointIterator++
      })
    }
    $(".field__info").click(function (e) {
      if ($(e.target).hasClass("field__input_delete")) {
        $(e.target).parent().remove()
      }
    })

    function setDownloadLink(pointsStr, all = false) {
      if (downloadForm.length) {
        downloadForm.find('input[name="points"]').val(btoa(pointsStr))
        downloadForm.find('input[name="all"]').val('false')
      }
      if(all === true){
        downloadForm.find('input[name="all"]').val('true')
      }
    }
  })
})(jQuery)
